import React from "react";
import astronaut from '../images/astronauta.svg';
import saturn from '../images/pianeta.svg';
import rocket from '../images/razzo.svg';

const wrapperStyle = {
    gridArea: "column",
    flexDirection: "column"
};


export const HomeIntesaCentralSvgs = props => {
    return (
            <div key="HomeIntesaCentralSvgs" id="home-intesa-central-svgs" style={wrapperStyle}>
                <div key="astronaut" id="astronaut" style={{marginTop: "100px", float: "left"}}>
                    <img src={astronaut} alt="Icona astronauta" />
                </div>
                <div key="saturn" id="saturn" style={{marginTop: "157px", marginLeft: "13px"}}>
                    <img src={saturn} alt="Icona pianeta" />
                </div>
                <div key="rocket" id="rocket" style={{marginTop: "115px", float: "left"}}>
                    <img src={rocket} alt="Icona razzo" />
                </div>
            </div>
    );
};
