import React from "react";

import background from '../images/bg-definitivo.svg';
import goToPodcastIcon from '../images/goToPodcast.png';
import goToCountdown from '../images/goToCountdown.png';
import goToManual from '../images/goToManual.png';
import goToRipetizioni from '../images/goToRipetizioni.png';
import {Footer} from "./Footer";
import {HomeIntesaLinkTo} from "./HomeIntesaLinkTo";
import {HomeIntesaCentralSvgs} from "./HomeIntesaCentralSvgs";
import {Header} from "./Header";
import "../styles&Fonts/HomeIntesa.scss";
import astronaut from '../images/astronauta.svg';
import saturn from '../images/pianeta.svg';
import rocket from '../images/razzo.svg';
import {MobileIconSvg} from "./MobileIconSvg";

const homeStyle = {
    width: "100%",
    backgroundImage: `url(${background})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden"
};

const descriptionStyle = {
    fontWeight: "bold",
    color: "#FFFFFF",
};

const homeIntesaLinksStyle = {
    display: "grid",
};



export const HomeIntesa = () => {
    return (
        <>
            <Header showNavbar={false} selected={0} />
            <div style={homeStyle} key="HomeIntesa" id="home-intesa">
                    <div style={descriptionStyle} key="HomeDescription" id="home-description">
                        Houston, abbiamo un problema… l’esame di Maturità!
                        Podcast, consigli, approfondimenti e i Tutor selezionati da
                        Skuola.net | Ripetizioni.it sono qui per portare a termine la missione con te!
                    </div>
                <div key="LinkS" id="home-intesa-links" style={homeIntesaLinksStyle}>
                    <HomeIntesaLinkTo label="Ripetizioni" altToInsert="Click per andare su ripetizioni.it" directLink="https://www.ripetizioni.it/?utm_source=skuola&utm_medium=content-hub-intesa-sanpaolo&utm_campaign=missione-maturita" icon={goToRipetizioni} gridAreaName="first"/>
                    <MobileIconSvg keyProps="AstronautMobile" id="astronaut-mobile" src={astronaut} alt="Icona astronauta mobile" gridArea="astronaut-mobile"/>
                    <HomeIntesaLinkTo label="Podcast" altToInsert="Click per andare alla pagina con i podcast" link="podcast" icon={goToPodcastIcon} gridAreaName="second"/>
                    <MobileIconSvg keyProps="SaturnMobile" id="saturn-mobile" src={saturn} alt="Icona saturno mobile" gridArea="saturn-mobile"/>
                    <HomeIntesaLinkTo label="Mission" altToInsert="Click per andare alla pagina con i video" link="manual" icon={goToManual} gridAreaName="third"/>
                    <HomeIntesaCentralSvgs />
                    <MobileIconSvg keyProps="RocketMobile" id="rocket-mobile" src={rocket} alt="Icona razzo mobile" gridArea="rocket-mobile"/>
                    <HomeIntesaLinkTo label="Countdown" altToInsert="Click per andare alla pagina del countdown" link="countdown" icon={goToCountdown} gridAreaName="fourth"/>
                </div>
                <Footer />
            </div>
        </>
    );
};
