import React, {useEffect,useState} from "react";
import SimpleImageSlider from "react-simple-image-slider";

import closeIcon from "../images/x.svg";

export const RushSlider = props => {
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const minSwipeDistance = 50;
    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = (e) => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        const wrapperClosest = e.target.closest(".modal-content-wrapper");

        if (isLeftSwipe || isRightSwipe){
            wrapperClosest.getElementsByTagName("button")[isLeftSwipe ? 1 : 0].click();
        }
    }
    return (
        <span id={"slideshow-number-" + props.position} key={"slideshow-number-" + props.position} className="slideshow-rush-wrapper">
            <div key={"close-rush-" + props.position} className="close-rush">
                <img src={closeIcon} title="Premi per chiudere" alt="Icona chiudi" className="close-rush-img"/>
            </div>
            <span onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} id={"modal-content-wrapper-mobile" + props.position} key={"modal-content-wrapper-mobile" + props.position} className="modal-content-wrapper modal-content-wrapper-mobile">
                        <SimpleImageSlider
                            width={340}
                            height={340}
                            images={props.images}
                            showNavs={true}
                        />
                    </span>
            <span onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} id={"modal-content-wrapper-tablet" + props.position} key={"modal-content-wrapper-tablet" + props.position} className="modal-content-wrapper modal-content-wrapper-tablet">
                        <SimpleImageSlider
                            width={625}
                            height={625}
                            images={props.images}
                            showNavs={true}
                        />
                </span>
        </span>

    );
};
