import React from "react";
import "../styles&Fonts/Description.scss";

const descriptionTitleStyle = {
    margin: "auto",
    marginTop: "15px",
    marginBottom: "10px",
    color: "#FFF203",
    fontSize: "18px",
    lineHeight: "35px",
};

const descriptionTextStyle = {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#FFFFFF"
};


export const Description = (props) => {
    return (
        <div key={props.keyProp} id="description-pages">
            <div key={props.keyTitle} style={descriptionTitleStyle} className="description-title">
                {props.title}
            </div>
            <div key={props.keyText} style={descriptionTextStyle} className="description-text">
                {props.textHtml}
            </div>
        </div>
    );
};
