import React from "react";
import background from "../images/bg-definitivo.svg";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {Description} from "./Description";

import "../styles&Fonts/Barbero.scss";

const barberoStyle = {
    width: "100%",
    backgroundImage: `url(${background})`,
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden"
};


export const Barbero = props => {
    return (
        <>
            <Header showNavbar={true} selected={1} />
            <div key="Podcast" id="podcast-main" style={barberoStyle}>
                <Description
                    keyProp="PodcastDescription"
                    keyTitle="BDTitle"
                    title="Trasmissioni... dallo spazio!"
                    keyText="BDText"
                    textHtml={<>
                        Tutti pronti per la... Missione Maturità? Per aiutarti a prepararti al meglio, <b>Intesa Sanpaolo On Air</b> dedica ai maturandi una <b>playlist di podcast</b> utili al ripasso, spaziando tra letteratura italiana, scienze e storia e accompagnando gli studenti nelle giornate di <b>“studio matto e disperatissimo"</b>. Tra gli autori grandi nomi come Alessandro Barbero, Dario Fabbri e Gabriella Greison, per una playlist in costante aggiornamento.
                    </>}
                />
                <div key="PodcastWrapper" id="podcast-wrapper">
                    <iframe title="Maturit\u00e0: ascoltando si ripassa" id="ds-7a53197d-94ff-481f-afae-bc4fcd7362c0"
                            src="//d172q3toj7w1md.cloudfront.net/widgets/podcastPlayer/v2/widget.html?resourceId=7a53197d-94ff-481f-afae-bc4fcd7362c0&autoplay=true&downloadEnabled=false&analytics=taelium-post-message&externalLinksEnabled=true&autoAdvanceEnabled=true&enableTeaser=false&playerIframeId=ds-7a53197d-94ff-481f-afae-bc4fcd7362c0"
                            width="100%" height="100%" allowFullScreen
                            allow="fullscreen" frameBorder="0"></iframe>
                </div>
                <Footer />
            </div>
        </>
    );
};