import React from "react";
import { Link } from "react-router-dom";

const wrapperStyle = {
    width: "308px"
};

const anchorStyle = {
    maxWidth: "100%"
};

const imgStyle = {
    maxWidth: "100%"
};

export const HomeIntesaLinkTo = props => {
    return (
            <div key={"HomeIntesaLinkTo" + props.label} id={"home-intesa-link-to" + props.label} style={{
                wrapperStyle,
                gridArea: props.gridAreaName
            }}>
                {
                    props.directLink ?
                        <a href={props.directLink} target="_blank" style={anchorStyle}>
                            <img src={props.icon} alt={props.altToInsert} title={props.altToInsert} style={imgStyle} />
                        </a>
                        :
                        <Link to={ "/" + props.link} style={anchorStyle}>
                            <img
                                src={props.icon}
                                alt={props.altToInsert}
                                title={props.altToInsert}
                                style={
                                    {
                                        ...imgStyle,
                                        ...props.styleToAddToImg ?? {}
                                    }
                                } />
                        </Link>
                }

            </div>
    );
};
