import './App.css';
import { Routes, Route } from "react-router-dom";
import {HomeIntesa} from "./components/HomeIntesa";
import {Barbero} from "./components/Barbero";
import {Countdown} from "./components/Countdown";
import {Manual} from "./components/Manual";
import {usePageTracking} from "./usePageTracking";

function App() {
    usePageTracking();
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<HomeIntesa />} />
                <Route path="podcast" element={<Barbero />} />
                <Route path="countdown" element={<Countdown />} />
                <Route path="manual" element={<Manual />} />
            </Routes>
        </div>
    );
}

export default App;
