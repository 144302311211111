import React from "react";
import miniMoon from '../images/Moon-min-halfi.svg';
import "../styles&Fonts/Footer.scss";

const footerStyle = {
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundPosition: "top"
};

const descriptionFooterStyle = {

    color: "#FFFFFF ",
    textAlign: "left"
};


export const Footer = () => {
    return (
            <div style={footerStyle} key="Footer" id="footer-intesa">
                <div key="DescriptionFooter" id="description-footer" style={descriptionFooterStyle}>
                    <div key="MobileMoon" id="mobile-moon">
                        <img src={miniMoon} alt="Icona luna mobile" />
                    </div>
                    <span key="FooterDescriptionText" id="footer-description-text">
                        <b>Messaggio pubblicitario</b>:<br id="messaggio-pubblicitario"/> il contributo messo a disposizione da Intesa Sanpaolo è richiedibile dal 10/06/2022 al 31/07/2022, ed è valido fino ad esaurimento dei 1.500 pacchetti disponibili. Ogni pacchetto consente di fruire di ore di lezione per un controvalore di 50€ ed è accreditabile entro il 31/07/2022. Una volta esaurito il contributo messo a disposizione da Intesa Sanpaolo, lo studente potrà continuare a prenotare ore di lezione secondo i termini e le condizioni di pagamento indicati sul sito ripetizioni.it.
                    </span>
                </div>
            </div>
    );
};
