import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const pressImgModal = (src, label, positionSlide) => {
    const modalToShow = document.getElementById("img-modal-manual");
    const modalSlideToShow = document.getElementById("slideshow-number-"+ positionSlide);
    if (modalToShow) {
        window.dataLayer.push(
            {
                'event': 'click_on_show_slideshow',
                'category': 'Visualizza contenuto',
                'action': 'Play ' + label,
                'label': 'Play ' + label,
            }
        );
        modalToShow.style.display = "flex";
        modalSlideToShow.style.display = "flex";
    }
}

export const ImageManual = props => {
    return (
        <div key={props.keyP} id={props.keyP} className="single-img-wrapper" onClick={() => pressImgModal(props.src, '"' + props.desc + '"', props.positionSlide)}>
            <LazyLoadImage
                key={props.keyP + "img"}
                className="img-missione-intesa" alt={props.desc} src={props.src} />
            <div key={props.keyP + "img-desc"} className="img-desc">
                {props.desc}
            </div>
        </div>
    );
};


