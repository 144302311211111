import React from "react";
import {Link} from "react-router-dom";

import "../styles&Fonts/NavBarNormalButton.scss";

export const NavBarNormalButton = props => {
    return (
        props.linkDirect ?
            <a href={props.linkDirect} target="_blank" rel="noreferrer" className="navbar-single-element" style={{
                ...isSelectedBg(props.selected)
            }} >
                <div>{props.label}</div>
            </a> :
            <Link to={ "/" + props.link} className={isSelectedClass(props.selected)} style={{
                ...isSelectedBg(props.selected)
            }} >
                <div>{props.label}</div>
            </Link>
    );
};

const isSelectedBg = (selected) => {
    return selected ?
        {backgroundColor: "#FFFFFF"} : {};
}

const isSelectedClass = (selected) => {
    return selected ?
        'navbar-single-element navbar-selected' : 'navbar-single-element'
}
