import React from "react";
import {Link} from "react-router-dom";
import logo from '../images/intesa-logo.svg';
import logoSkuola from '../images/Skuola.net _ Ripetizioni.svg';
import {NavBar} from "./NavBar";

import "../styles&Fonts/Header.scss";

const headerStyle = {
    background: "#FFFFFF",
    position: "sticky",
    top: "0",
    zIndex: 3
};




export const Header = (props) => {
    return (
        <>
            <div style={headerStyle} id="header-missione">
                <Link to={ "/"} className="logo-skuola-desktop">
                    <div key="LogoSkuola" id="logo-skuola" >
                        <img src={logoSkuola} alt="Logo Skuola.net | Ripetizioni" />
                    </div>
                </Link>
                <Link to={ "/"} className="logo-missione-desktop">
                    <div key="LogoMissioneIntesa" id="logo-missione-intesa">
                        <img src={logo} alt="Logo missione intesa"/>
                    </div>
                </Link>
            </div>
            <Link to={ "/"} className="handle-mobile logo-skuola">
                <div key="LogoSkuola" id="logo-skuola-mobile" >
                    <img src={logoSkuola} alt="Logo Skuola.net | Ripetizioni" />
                </div>
            </Link>
            <div className="handle-mobile logo-intesa">
                <Link to={ "/"} className="logo-anchor">
                    <div key="LogoMissioneIntesa" id="logo-missione-intesa-mobile">
                        <img src={logo} alt="Logo missione intesa"/>
                    </div>
                </Link>
                    {
                        props.showNavbar ?
                            <NavBar selected={props.selected} position="mobile" /> : ''
                    }

            </div>
            {
                props.showNavbar ?
                    <NavBar selected={props.selected} position="tablet"/> : ''
            }
            {
                props.showNavbar ?
                    <NavBar selected={props.selected} position="desktop"/> : ''
            }

        </>
    );
};
