import React, {useEffect} from "react";
import {Header} from "./Header";
import {Footer} from "./Footer";
import background from "../images/bg-definitivo.svg";
import {Description} from "./Description";

import "../styles&Fonts/Manual.scss";
import {Video} from "./Video";
import {ImageManual} from "./ImageManual";

//Immagini primo post
import p10 from "../images/post1/1.png";
import p11 from "../images/post1/2.png";
import p12 from "../images/post1/3.png";
import p13 from "../images/post1/4.png";
import p14 from "../images/post1/5.png";
import p15 from "../images/post1/6.png";

//Immagini secondo post
import p20 from "../images/post2/1.png";
import p21 from "../images/post2/2.png";
import p22 from "../images/post2/3.png";
import p23 from "../images/post2/4.png";
import p24 from "../images/post2/5.png";
import p25 from "../images/post2/6.png";
import p26 from "../images/post2/7.png";
import p27 from "../images/post2/8.png";

//Immagini terzo post
import p30 from "../images/post3/1.png";
import p31 from "../images/post3/2.png";
import p32 from "../images/post3/3.png";
import p33 from "../images/post3/4.png";
import p34 from "../images/post3/5.png";
import p35 from "../images/post3/6.png";
import p36 from "../images/post3/7.png";
import p37 from "../images/post3/8.png";

//Immagini quarto post
import p40 from "../images/post4/1.png";
import p41 from "../images/post4/2.png";
import p42 from "../images/post4/3.png";
import p43 from "../images/post4/4.png";
import p44 from "../images/post4/5.png";
import p45 from "../images/post4/6.png";
import p46 from "../images/post4/7.png";

import {RushSlider} from "./RushSlider";

//Anteprima video
import preview0 from "../images/preview0.png";
import preview1 from "../images/preview1.png";
import preview2 from "../images/preview2.png";
import preview3 from "../images/preview3.png";
import preview4 from "../images/preview4.png";
import preview5 from "../images/preview5.png";
import preview6 from "../images/preview6.png";
import preview7 from "../images/preview7.png";

const imagesToSlide = [
    {
        "position": 0,
        "images": [
            { url: p10 },
            { url: p11 },
            { url: p12 },
            { url: p13 },
            { url: p14 },
            { url: p15 }
        ]
    },
    {
        "position": 1,
        "images": [
            { url: p20 },
            { url: p21 },
            { url: p22 },
            { url: p23 },
            { url: p24 },
            { url: p25 },
            { url: p26 },
            { url: p27 }
        ]
    },
    {
        "position": 2,
        "images": [
            { url: p30 },
            { url: p31 },
            { url: p32 },
            { url: p33 },
            { url: p34 },
            { url: p35 },
            { url: p36 },
            { url: p37 }
        ]
    },
    {
        "position": 3,
        "images": [
            { url: p40 },
            { url: p41 },
            { url: p42 },
            { url: p43 },
            { url: p44 },
            { url: p45 },
            { url: p46 }
        ]
    }
];

const countMainStyle = {
    width: "100%",
    backgroundImage: `url(${background})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden"
};

const videosToInsert = [
    {
        "src":"https://video.skuola.net/6412419cdcf.mp4",
        "keyP":"Video0",
        "videoDesc":"Com'è fatta la prima prova?",
        "poster":preview0
    },
    {
        "src":"https://video.skuola.net/a487534f659.mp4",
        "keyP":"Video1",
        "videoDesc":"Com'è fatta la seconda prova?",
        "poster":preview1
    },
    {
        "src":"https://video.skuola.net/5c094d56909.mp4",
        "keyP":"Video2",
        "videoDesc":"Gli errori di italiano da evitare assolutamente",
        "poster":preview2
    },
    {
        "src":"https://video.skuola.net/01a5ebc1d18.mp4",
        "keyP":"Video3",
        "videoDesc":"Cosa si può portare alle prove scritte?",
        "poster":preview3
    },
    {
        "src":"https://video.skuola.net/7cdb422692e.mp4",
        "keyP":"Video4",
        "videoDesc":"Come studiare se si ha poco tempo?",
        "poster":preview4
    },
    {
        "src":"https://video.skuola.net/e49c50838c5.mp4",
        "keyP":"Video5",
        "videoDesc":"Consigli per la gestione dell'ansia?",
        "poster":preview5
    },
    {
        "src":"https://video.skuola.net/3ae747eda98.mp4",
        "keyP":"Video6",
        "videoDesc":"Com'è la prova orale?",
        "poster":preview6
    },
    {
        "src":"https://video.skuola.net/50df6d9f94a.mp4",
        "keyP":"Video7",
        "videoDesc":"Come sembrare più preparati alla prova orale?",
        "poster":preview7
    }
];

const printVideos = () => {
    return videosToInsert.map(function(video) {
        return <Video
            src={video['src']}
            keyP={video['keyP']}
            videoDesc={video['videoDesc']}
            poster={video['poster']}
        />;
    });
};

const imgsToInsert = [
    {
        "position":0,
        "src":p10,
        "keyP":"Img0",
        "desc":"Il voto di Maturità: come funziona?"
    },
    {
        "position":1,
        "src":p20,
        "keyP":"Img1",
        "desc":"Consigli per la gestione dell'ansia da esame"
    },
    {
        "position":2,
        "src":p30,
        "keyP":"Img2",
        "desc":"Prove scritte di Maturità: le cose essenziali da sapere (e ricordare)"
    },
    {
        "position":3,
        "src":p40,
        "keyP":"Img3",
        "desc":"Trucchi di public speaking per gli orali"
    }
];

const printImages = () => {
    return imgsToInsert.map(function(img) {
        return <ImageManual
            src={img['src']}
            keyP={img['keyP']}
            desc={img['desc']}
            positionSlide={img['position']}
        />;
    });
};

const closeImgModal = event =>{
    const modal = document.getElementById("img-modal-manual");
    if (modal && ( event.target.id === "img-modal-manual" || event.target.classList.contains("close-rush") || event.target.classList.contains("close-rush-img") ) ) {
        modal.style.display = "none";
        const allRushes = document.getElementsByClassName("slideshow-rush-wrapper");
        Array.from(allRushes).forEach(function (rush){
            rush.style.display = "none";
        });
    }
}

const printSlides = () => {
    return imagesToSlide.map(function(imgToSlide) {
        return <RushSlider
            position={imgToSlide['position']}
            images={imgToSlide['images']}
        />;
    });
}

export const Manual = props => {

    useEffect(() => {
        const videoButtons = document.getElementsByClassName(
            "video-react-big-play-button"
        );
        if (videoButtons.length > 0) {
            Array.from(videoButtons).forEach(function (button){
                if (button.dataset.listener === "1"){
                    return;
                }
                let labelGTM = "";
                const wrapperVideo = button.closest(".single-video-wrapper");
                if (wrapperVideo){
                    const descTmp = wrapperVideo.getElementsByClassName("video-desc")[0];
                    if (descTmp){
                        labelGTM = '"' + descTmp.innerText + '"';
                    }
                }

                button.setAttribute("data-listener", "1");
                button.addEventListener("click", function (){
                    window.dataLayer.push(
                        {
                            'event': 'click_on_show_video',
                            'category': 'Play Video',
                            'action': 'Play ' + labelGTM,
                            'label': 'Play ' + labelGTM,
                        }
                    );
                });
            });
        }
    }, [])

    return (
        <>
            <Header showNavbar={true} selected={2} />
            <div key="Manual" id="manual-main" style={countMainStyle}>
                <Description
                    keyProp="ManualDescription"
                    keyTitle="CDManual"
                    title="Manuali di istruzione per maturandi"
                    keyText="BDText"
                    textHtml={<>
                        <b>Come sono strutturate le prove?</b> Cosa chiedono all’orale? Quali errori evitare? Cosa si può portare agli scritti e cosa no? <b>Come gestire l’ansia?</b> Niente panico!<br />
                        Ci siamo noi con le nostre <b>info in pillole per aiutarvi</b> a superare al meglio questi momenti!
                    </>}
                />

                <div key="AllVideos" id="all-videos">
                    {printVideos()}
                </div>
                <div key="AllImages" id="all-images">
                    {printImages()}
                </div>
                <div id="img-modal-manual" key="img-modal" className="img-modal" onClick={closeImgModal}>
                    {printSlides()}
                </div>
                <div key="Explanation" id="explanation">
                    <div key="ExDesc" id="ex-desc">
                        I nostri manuali di istruzioni non finiscono qui.
                        Sul sito nella sezione Maturità troverai guide, simulazioni, tracce e soluzioni degli anni precedenti, esempi di relazioni per i PCTO.
                    </div>
                    <a key="ExButtonLink" id="ex-button-link" target="_blank" href="https://www.skuola.net/maturita/?utm_source=skuola&utm_medium=content-hub-intesa-sanpaolo&utm_campaign=missione-maturita" rel="noreferrer">
                        Scopri di più
                    </a>
                </div>
                <Footer />
            </div>
        </>
    );
};


