import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {NavBarNormalButton} from "./NavBarNormalButton";
import chevron from '../images/chevron-left.svg';
import background from '../images/bg-definitivo.svg';

import "../styles&Fonts/Navbar.scss";

const navbarStyle = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
};




export const NavBar = props => {

    useEffect(() => {
        const selectedButton = document.getElementsByClassName(
            "navbar-selected"
        )[0];
        if (selectedButton) {
            selectedButton.scrollIntoView({ block: "end", inline: "start" });
        }
    }, [])

    return (
            <div style={navbarStyle} id={"navbar-position-"+props.position}>
                <Link to={ "/"} className="go-to-home-navbar">
                    <span style={{
                        alignItems: "center",
                        display: "flex",
                        marginRight: "10px"
                    }}>
                        <img src={chevron} alt="Torna alla home" title="Torna alla home" />
                    </span>
                    Torna alla pagina iniziale
                </Link>
                <NavBarNormalButton linkDirect="https://www.ripetizioni.it/?utm_source=skuola&utm_medium=content-hub-intesa-sanpaolo&utm_campaign=missione-maturita"  selected={props.selected === 4} label="Addestramento missione" />
                <NavBarNormalButton link="podcast"  selected={props.selected === 1} label="Trasmissioni... dallo spazio!" />
                <NavBarNormalButton link="manual"  selected={props.selected === 2} label="Manuali d'istruzione per maturandi" />
                <NavBarNormalButton link="countdown"  selected={props.selected === 3} label="Notte Prima degli Esami" />
                <div id="space-hurry" style={{width: "1px"}}></div>
            </div>
    );
};
