import React from "react";

import {
    Player,
    BigPlayButton
} from 'video-react';

import playButton from "../images/playButton.svg";

const showUnderVideo = keyP => {
    const thisImage = document.getElementById("imagePreVideo"+keyP);
    if (thisImage) {
        const thisImageWrapper = thisImage.closest(".single-video-wrapper"+keyP);
        const thisImageVideo = thisImageWrapper.getElementsByClassName("video-react")[0];
        if (thisImageVideo){
            thisImageVideo.style.display = "block";
            thisImageVideo.getElementsByClassName("video-react-big-play-button")[0].click();
        }
        thisImage.remove();
    }
}

export const Video = props => {
    return (
        <div key={props.keyP} id={props.keyP} className={"single-video-wrapper single-video-wrapper"+props.keyP}>
            <div className={"imagePreVideo"} id={"imagePreVideo"+props.keyP} onClick={() => showUnderVideo(props.keyP)}>
                <img src={playButton}  className="playButton" />
                <img src={props.poster}  className="imagePreVideo" />
            </div>
            <Player
                playsInline
                key={props.keyP + "v"}
                src={props.src}
                playIcon={<button>Play</button>}
            >
                <BigPlayButton position="center" class="video-skuola-intesa" />
            </Player>
            <div key={props.keyP + "desc"} className="video-desc">
                {props.videoDesc}
            </div>
        </div>
    );
};


