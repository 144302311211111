import React from "react";
import {Header} from "./Header";
import {Footer} from "./Footer";
import background from "../images/bg-definitivo.svg";
import {Description} from "./Description";

import logoCalendar from "../images/calendar_icon.svg";
import imgNPDE from "../images/immagine-notte.jpg";
import imgNPDEMobile from "../images/vertical-countdown.jpg";

import "../styles&Fonts/Countdown.scss";

const countdownStyle = {
    width: "100%",
    backgroundImage: `url(${background})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden"
};

const defaultCDNumberStyle = {
    color: "#FFF203"
};

const defaultCDTextStyle = {
   color: "#FFFFFF"
};


/*
//Gestione JS countdown
const countDownDate = new Date("Jun 21, 2022 20:00:00").getTime();

const getDifference = (kindOfUnit) => {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    if (distance < 0) {
        clearInterval(interval);
        return 0;
    }
    switch (kindOfUnit){
        case "days":
            return Math.floor(distance / (1000 * 60 * 60 * 24));
        case "hours":
            return Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        case "minutes":
            return Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        case "seconds":
            return Math.floor((distance % (1000 * 60)) / 1000);
        default:
            return 0;
    }
}

const setCDDate = () => {
    if (document.getElementById("countdown-main")){
        const days = getDifference("days");
        const hours = getDifference("hours");
        const minutes = getDifference("minutes");
        const seconds = getDifference("seconds");

        // Display the result in the element with id="demo"
        document.getElementById("CD-days-number").innerText = days.toString();
        document.getElementById("CD-hours-number").innerText = hours.toString();
        document.getElementById("CD-minutes-number").innerText = minutes.toString();
        document.getElementById("CD-seconds-number").innerText = seconds.toString();
    }
}

const interval = setInterval(function() {
    setCDDate();
}, 1000);

//Funzioni pulsante memo
const clickOnSTD = () => {
  const stdDropdownList = document.getElementsByClassName("addeventatc_dropdown")[0];
  if (stdDropdownList) {
      window.dataLayer.push(
          {
              'event': 'click_on_add_calendar',
              'category': 'Aggiungi al calendario',
              'action': 'Aggiungi al calendario ',
              'label': 'Aggiungi al calendario '
          }
      );
      stdDropdownList.classList.toggle("addeventatc_dropdown_flex");
  }
}
const googleCalendarLink = "https://calendar.google.com/event?action=TEMPLATE&tmeid=MG1pbHY0NDVuMzFiNGttbDVmNnE2N3NwMGYgc2t1b2xhZXZlbnRpY2FsZW5kYXJAbQ&tmsrc=skuolaeventicalendar%40gmail.com";
*/

export const Countdown = props => {
    return (
        <>
            <Header showNavbar={true} selected={3} />
            <div key="Countdown" id="countdown-main" style={countdownStyle}>
                <Description
                    keyProp="CountdownDescription"
                    keyTitle="CDTitle"
                    title="Notte Prima degli Esami"
                    keyText="BDText"
                    textHtml={<>
                        Il countdown alla prima prova di Maturità è terminato. Ma continuano le trasmissioni dal centro di Controllo della Missione Maturità: rivedi i migliori consigli sull'esame degli ospiti che hanno preso parte alla LIVE per la Notte Prima degli Esami.
                    </>}
                />
                {/*<div key="CountdownWrapper" id="count-down-wrapper">
                    <div key="CDDays" id="CD-days" className="CD-single-element">
                        <span key="CD-days-number" id="CD-days-number" className="CD-single-element-number" style={defaultCDNumberStyle}>
                            {getDifference("days")}
                        </span>
                        <span key="CD-days-text" style={defaultCDTextStyle}>Giorni</span>
                    </div>
                    <div key="CDHours" id="CD-hours" className="CD-single-element">
                        <span key="CD-hours-number" id="CD-hours-number" className="CD-single-element-number" style={defaultCDNumberStyle}>
                            {getDifference("hours")}
                        </span>
                        <span key="CD-hours-text" style={defaultCDTextStyle}>Ore</span>
                    </div>
                    <div key="CDMinutes" id="CD-minutes" className="CD-single-element">
                        <span key="CD-minutes-number" id="CD-minutes-number" className="CD-single-element-number" style={defaultCDNumberStyle}>
                            {getDifference("minutes")}
                        </span>
                        <span key="CD-minutes-text" style={defaultCDTextStyle}>Minuti</span>
                    </div>
                    <div key="CDSeconds" id="CD-seconds" className="CD-single-element">
                        <span key="CD-seconds-number" id="CD-seconds-number" className="CD-single-element-number" style={defaultCDNumberStyle}>
                            {getDifference("seconds")}
                        </span>
                        <span key="CD-seconds-text" style={defaultCDTextStyle}>Secondi</span>
                    </div>
                </div>

                <div key="SaveTheDate" title="Premi per aggiungere l'evento sul tuo calendario" className="addeventatc save-the-date-wrapper" data-styling="none" onClick={clickOnSTD}>
                    <div id="wrapper-label-std">
                        <span key="CalendarLogo" className="calendar-logo">
                            <img src={logoCalendar} alt="Logo calendario"/>
                        </span>
                        <span key="Calendartext" className="calendar-text">
                            Aggiungi il memo sul tuo calendario!
                        </span>
                    </div>
                    <span id="addeventatc1-drop"
                          className="addeventatc_dropdown addeventatc-selected"
                          aria-hidden="false" aria-labelledby="addeventatc1">
                        <a href="/misc/notte-prima-degli-esami-skuola-intesa.ics" download className="link-calendar link-calendar-ios">
                            <span className="ateappleical" id="addeventatc1-appleical" role="menuitem"
                                  tabIndex="-1">
                                    Apple
                            </span>
                        </a>
                        <a className="link-calendar link-calendar-google" target="_blank" rel="noreferrer" href={googleCalendarLink}>
                            <span
                            className="ategoogle" id="addeventatc1-google" role="menuitem" tabIndex="-1">
                                    Google
                            </span>
                        </a>
                    </span>
                </div>*/}
                <div key="ImageNottePrimaDegliEsami" id="low-content-npde" >
                    <iframe src="https://www.youtube.com/embed/Xu3bKwV0BDo"
                            title="Notte Prima degli Esami 2022 Live | Missiona Maturità in collaborazione con Intesa Sanpaolo"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>

                </div>

                <Footer />
            </div>
        </>
    );
};


